
.form-label {
  align-items: flex-end;
  display: flex;
  margin: 0 0 1rem;
  text-transform: uppercase;
  font-size: 1.4rem;
  height: 3rem;
  line-height: 1.125;
  letter-spacing: .05em;
  color: $form-label-color;
  user-select: none;
  font-family: $font-family-2;
}

