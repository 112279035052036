
.gallery-grid {
  display: flex;
  flex-wrap: wrap;
  margin: -1rem;
}

.gallery-grid__item {
  position: relative;
  flex: 0 0 calc(50% - 1rem);
  width: calc(50% - 1rem);
  max-width: calc(50% - 1rem);
  margin: 0.5rem;

  @include respondMf(sm) {
    flex: 0 0 calc(50% - 2rem);
    width: calc(50% - 2rem);
    max-width: calc(50% - 2rem);
    margin: 1rem;
  }

  @include respondMf(lg) {
    flex: 0 0 calc(33.3333% - 2rem);
    width: calc(33.3333% - 2rem);
    max-width: calc(33.3333% - 2rem);
  }

  &::after {
    content: '';
    position: relative;
    display: block;
    height: 0;
    padding-bottom: 100%;
  }
}

.gallery-grid__image {
  position: absolute;
  top: 1rem;
  left: 1rem;
  right: 1rem;
  bottom: 1rem;
  margin: 0;
}

.gallery-grid__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.gallery-grid__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  font-family: 'object-fit:cover;object-position: center center;';
  transform: scale(1,1);
  transition: .25s transform;

  .gallery-grid__item:hover & {
    transform: scale(1.05,1.05);
  }
}
