
// paths
$path-image: '../img';

// mf - mobile first
// nemenit
$grid-breakpoints-mf: (
        xs: 25em, // 400px
        sm: 36em, // 576px
        md: 48em, // 768px
        lg: 62em, // 992px
        xlg: 75em, // 1200px
        sm-desktop: 90em, // 1440px
        desktop: 120em // 1920px
);

// fonts
$font-family-1: 'Roboto Condensed', sans-serif;
$font-family-2: 'Roboto Condensed', sans-serif;

// colors
// zde definovat nove barvy
$color-text: #434343;
$color-light-text: #6d6a64;
$color-border: #cdcdcd;

$color-background: #f2f2f2;

$color-primary: #213760;
$color-primary-light: #445a86;
$color-primary-dark: #c23503;

$color-secondary: #434343;
$color-secondary-light: #5c5c5c;
$color-secondary-dark: #2b898b;
$color-secondary-bg: #234b4c;

$color-error: #da1d1d;
