.header-carousel-container {
  bottom: 0;
  display: none;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;

  @include respondMf(lg) {
    display: flex;
  }
}

.header-carousel-navigation {
  bottom: 6rem;
  margin-right: -9rem;
  position: absolute;
  right: 42rem;
  z-index: 10;

  @include respondMf(lg) {
    right: 35rem;
  }

  @include respondMf(xlg) {
    bottom: 10rem;
    right: 47rem;
  }

  @include respondMf(sm-desktop) {
    right: 60rem;
  }

  @include respondMf(desktop) {
    right: 80rem;
  }
}

.header-carousel__pagination {
  bottom: 5rem;
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.header-carousel__bullet {
  border: 2px solid $color-border;
  border-radius: 50%;
  cursor: pointer;
  height: 1.2rem;
  margin-right: 1.2rem;
  transition: all 0.25s;
  width: 1.2rem;

  &:hover,
  &:focus {
    border-color: $color-primary;
    outline: none;
  }

  &.tns-nav-active {
    background-color: $color-primary;
    border-width: 0.5rem;
    cursor: default;
    height: 2.5rem;
    margin: -0.65rem 0.7rem -0.65rem -0.8rem;
    width: 2.5rem;

    &:hover {
      border-color: $color-border;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}

.header-carousel__image {
  font-family: 'object-fit:contain';
  margin: 0;
  object-fit: contain;
  position: absolute;
  right: 0;
  top: 12rem;
  width: 42rem;
  z-index: 5;

  @include respondMf(lg) {
    width: 35rem;
  }

  @include respondMf(xlg) {
    width: 45rem;
  }

  @include respondMf(sm-desktop) {
    width: 60rem;
  }

  @include respondMf(desktop) {
    width: 80rem;
    top: 11rem;
  }
}