.food-menu {
  background-color: #fff;
  position: relative;

  @include respondMf(xs) {
    max-width: 80%;
  }

  @include respondMf(xlg) {
    max-width: 100%;
  }
}

.food-menu--shadow {
  box-shadow: 0 0.5rem 3rem 0 rgba(#000, 0.1);

  .food-menu__link:hover {
    box-shadow: 0 0.5rem 3rem 0 rgba(#000, 0.1);
  }
}

.food-menu--padding {
  padding: 2.5rem 2rem;

  @include respondMf(sm) {
    padding: 3.6rem 3rem;
  } 
}

.food-menu__link {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: box-shadow 0.25s;
  z-index: 10;

  &:hover {
    + .food-menu__inner .content h2 {
      color: $color-primary;
    }
  }
}

.food-menu__inner {
  max-width: calc(100% - 6rem);

  .content h2 {
    transition: color 0.25s;
  }

  .ul-symbol li {
    margin-bottom: 3rem;
  }
}

.food-menu__bg-title {
  bottom: 2rem;
  color: $color-background;
  font-family: $font-family-2;
  font-size: 4rem;
  line-height: 1;
  position: absolute;
  right: -6rem;
  transform: translateY(100%);
  width: 150px;

  @include respondMf(sm) {
    font-size: 5rem;
    top: -0.5rem;
    transform: none;
    width: 180px;
  }
}

.food-menu__text {
  display: inline-block;
  font-family: $font-family-2;
  font-size: 1.8rem;
  min-height: 4.5rem;
  position: relative;
  z-index: 5;
}
