.icon-horizontal {
  position: relative;
  align-items: center;
  display: flex;
  margin-bottom: 3rem;

  h3 {
    font-size: 2rem;
    margin: 0;
  }
}

.icon-horizontal__container {
  margin-right: 1.6rem;
}

.icon-horizontal__img {
  height: 3rem;
  transform: scale(1,1);
  transition: .1s transform;

  .icon-horizontal:hover & {
    transform: scale(1.075,1.075);
  }
}

.icon-horizontal__img--relief {
  height: 3.8rem;
  margin: -0.4rem 0;
}

.content a.icon-horizontal__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:link,
  &:visited,
  &:hover,
  &:active {
    color: transparent;
  }
}