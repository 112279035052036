
.nav-toggle {
  display: none;
}

.nav-toggle-button {
  display: block;
  width: 3rem;
  height: 3rem;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  cursor: pointer;
  color: transparent;

  position: relative;
  z-index: 1010;

  @include respondMf(lg) {
    display: none;
  }

  .nav-toggle:checked + & {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg id='Outlined' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle/%3E%3Cg id='Fill'%3E%3Cpolygon fill='%23fff' points='28.71 4.71 27.29 3.29 16 14.59 4.71 3.29 3.29 4.71 14.59 16 3.29 27.29 4.71 28.71 16 17.41 27.29 28.71 28.71 27.29 17.41 16 28.71 4.71'/%3E%3C/g%3E%3C/svg%3E");
  }
}

.menu-container--is-scrolled,
.menu-container--submenu {
  .nav-toggle-button {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23213760' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
}

.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  overflow: hidden;
  background-color: rgba($color-primary, .95);
  z-index: 1000;

  display: flex;
  align-items: center;
  padding: 8.5rem 2rem 7rem;
  transform: translateX(100%);
  transition: transform .25s;
  will-change: transform;

  @include respondMf(lg) {
    order: -1;
    position: relative;
    display: block;
    height: auto;
    padding: 0;
    background-color: transparent;
    transform: translateX(0);
    transition: none;
    will-change: auto;
    overflow: visible;
  }

  .nav-toggle:checked ~ & {
    transform: translateX(0);
  }
}

.nav__list {
  display: block;
  height: calc(100% - 3rem);
  max-height: 100%;
  overflow: auto;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;

  @include respondMf(lg) {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    width: auto;
    overflow: visible;
  }
}

.nav__link {
  position: relative;
  display: inline-block;
  font-weight: 300;
  margin: 1rem 0 1rem 4rem;
  padding: 0.5rem 0;
  text-transform: uppercase;
  text-decoration: none;
  font-family: $font-family-2;
  font-size: 1.8rem;
  transition: opacity 0.25s;

  @include respondMf(md) {
    padding: 1rem 0;
    font-size: 2.5rem;
  }

  @include respondMf(lg) {
    margin: 0 2rem;
    padding: 0;
    font-size: 1.6rem;
  }

  &:hover {
    opacity: 0.6;
  }
}

.nav__link--active {
  border-bottom: 1px solid #fff;
}

.menu-container--is-scrolled,
.menu-container--submenu {
  .nav__link--active {
    @include respondMf(md) {
      border-color: $color-primary;
    }
  }
}

.nav__btn {
  margin: 4rem 0 0 4rem;

  @include respondMf(lg) {
    margin: 0 2rem;
  }
}
