.google-map {
  position: relative;
  height: 30rem;

  @include respondMf(md) {
    height: 46.85rem;
  }
}
.gm-style-pbc + div::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(33, 55, 96);
  opacity: 0.851;
}

.google-map .gm-style-pbc + div [title] {
  opacity: 1 !important;
}

.google-map .gm-style .gm-style-iw-c {
  left: -3rem !important;
  width: 100%;
  padding: 0;
  box-shadow: none;
  border: none !important;
  background-color: transparent;

  @include respondMf(sm) {
    width: auto;
  }

  @include respondMf(md) {
    left: 0 !important;
  }

  button { display: none !important; }
}

.google-map .gm-style .gm-style-iw-t::after {
  display: none !important;
}

.google-map h2 {
  margin: 0 0 2rem !important;
  font-size: 2.6rem;
  font-weight: 300;
  color: #fff;

  @include respondMf(md) {
    font-size: 3.6rem;
  }
}
.google-map .google-map__info {
  display: flex;

  > .content:first-of-type {
    margin-right: 1rem;

    @include respondMf(md) {
      margin-right: 4rem;
    }
  }
}
.google-map .content p {
  font-family: $font-family-1;
  font-size: 1.3rem;
  line-height: 1.2;
  color: #fff;

  @include respondMf(md) {
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: .1em;
  }
}
.google-map .gm-style .gm-style-iw-d {
  overflow: visible !important;
}
