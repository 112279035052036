.btn {
  background-color: $color-secondary;
  border-radius: 5rem;
  color: #fff;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 700;
  padding: 2rem 2.5rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.25s;

  &:hover,
  &:active {
    background-color: $color-secondary-light;
  }
}

.btn--v2 {
  background-color: transparent;
  border: 1px solid #fff;

  &:hover,
  &:active {
    background-color: #fff;
    color: $color-primary;
  }
}

.btn--v3 {
  background-color: $color-primary;

  &:hover,
  &:active {
    background-color: $color-primary-light;
  }
}

.btn--small {
  font-size: 1.4rem;
  font-weight: 300;
  text-transform: none;
}
