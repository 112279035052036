.benefit {
  padding: 0 3rem;

  .content h2 {
    font-size : 3.6rem;
    font-weight: 300;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .content p {
    text-align: center;
  }
}

.benefit__header {
  align-items: center;
  border: 1px solid $color-primary;
  border-radius: 50%;
  display: flex;
  height: 4.8rem;
  margin: 0 auto 3rem auto;
  padding: 0.8rem;
  width: 4.8rem;
}

.benefit__logo {
  font-family: 'object-fit:cover;object-position: center center;';
  margin-left: 0.2rem;
  object-fit: cover;
  object-position: center center;
}

.benefit__body {
  position: relative;

  @include respondMf(lg) {
    &::after {
      background: $color-border;
      content: '';
      height: 15rem;
      max-height: 100%;
      position: absolute;
      right: -4.5rem;
      top: 50%;
      transform: translateY(-50%);
      width: 0.1rem;
    }
  }
}

.benefit--last .benefit__body {
  &::after {
    display: none;
  }
}

