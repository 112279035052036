$space-amounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 110, 180, 250); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $space-amounts {

  .u-m-#{$space} {
    margin: #{$space/10}rem !important;
  }

  .u-m-n#{$space} {
    margin: -#{$space/10}rem !important;
  }

  @each $side in $sides {

    .u-m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space/10}rem !important;
    }

    .u-m-#{str-slice($side, 0, 1)}-n#{$space} {
      margin-#{$side}: -#{$space/10}rem !important;
    }

    .u-p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space/10}rem !important;
    }

    .u-p-#{str-slice($side, 0, 1)}-n#{$space} {
      padding-#{$side}: -#{$space/10}rem !important;
    }
  }
}

@each $side in $sides {

  .u-m-#{str-slice($side, 0, 1)}-auto{
    margin-#{$side}: auto !important;
  }
}

@each $space in $space-amounts {
  @each $side in $sides {

    @each $break, $break-value in $grid-breakpoints-mf {
      .u-m-#{$break}-#{str-slice($side, 0, 1)}-#{$space} {
        @include respondMf($break) {
          margin-#{$side}: #{$space/10}rem !important;
        }
      }

      .u-m-#{$break}-#{str-slice($side, 0, 1)}-n#{$space} {
        @include respondMf($break) {
          margin-#{$side}: -#{$space/10}rem !important;
        }
      }
    }

    @each $break, $break-value in $grid-breakpoints-mf {
      .u-p-#{$break}-#{str-slice($side, 0, 1)}-#{$space} {
        @include respondMf($break) {
          padding-#{$side}: #{$space/10}rem !important;
        }
      }
    }
  }
}
