.footer {
  background-color: $color-secondary;
  padding: 6rem 0;

  .content ul {
    margin: 0;
  }

  .content li {
    font-family: $font-family-2;
    padding: 0;
    text-transform: uppercase;

    a:link,
    a:visited {
      color: #fff;
      display: inline-block; // fix chrome column-count bug
    }

    &::before {
      display: none;
    }
  }
}

.footer__services.content ul {
  @include respondMf(xs) {
    column-count: 2;
    column-gap: 3rem;
  }

  @include respondMf(md) {
    column-count: 3;
  }

  @include respondMf(sm-desktop) {
    column-gap: 5rem;
    margin-left: 2rem;
  } 
}

.footer__logo {
  height: 4rem;
  max-width: 14rem;
}
