
.section {
  padding: 6rem 0;

  @include respondMf(md) {
    padding: 9rem 0;
  }
}
.section--bg-1 {
  background-color: $color-background;
}

.section--bg-2 {
  background-color: $color-primary;
  
  .content h2,
  .content p {
    color: #fff;
  }
}

.section--form {
  .content h2 {
    font-weight: 300;
  }
}

.section--banner {
  .content small {
    color: $color-primary;
    display: block;
    font-family: $font-family-2;
    font-size: 1.8rem;
    text-transform: uppercase;
    transition: color 0.25s;
  }

  .content a {
    font-size: 3rem;
    font-weight: 900;
    line-height: 1;
    text-decoration: none;

    @include respondMf(md) {
      font-size: 3.6rem;
    }

    &:link,
    &:visited {
      color: $color-primary;
    }

    &:hover,
    &:active {
      color: $color-primary-light;
    }
  }
}

.section--content {
  .content img {
    font-family: 'object-fit:cover;object-position: center center;';
    height: 24rem;
    object-fit: cover;
    object-position: center center;
    width: 60rem;

    @include respondMf(xs) {
      height: 37rem;
    }
  }

  .content h3 {
    color: $color-primary;
  }
}
