.header {
  padding: 7.6rem 0;
  position: relative;
  min-height: 50rem;

  @include respondMf(lg) {
    min-height: auto;
    height: 92.3rem;
    padding: 12rem 0;
  }
}

.header__bg {
  font-family: 'object-fit:cover;object-position: center center;';
  height: 100%;
  min-height: 55rem;
  object-fit: cover;
  object-position: center left;
  position: absolute;
  top: 0;
  width: 100%;

  @include respondMf(lg) {
    min-height: auto;
  }
}