.footer-label {
  background-color: $color-primary;
  font-family: $font-family-2;
  margin: -13.5rem -1.5rem 0 -1.5rem;
  padding: 4rem 1.5rem;
  text-align: right;

  @include respondMf(md) {
    border-bottom-right-radius: 14rem;
    border-top-right-radius: 14rem;
    margin-right: 0;
    padding: 4rem 8rem 4rem 3rem;
  }

  @include respondMf(lg) {
    padding: 6.5rem 10rem 5rem 3rem;
  }

  @media only screen and (min-width: 1280px) {
    margin-left: calc((-100vw + 128rem) / 2 - 1.5rem);
    padding-left: calc((100vw - 128rem) / 2 + 1.5rem);
  }

  .content {
    margin-bottom: 2rem;
    text-align: left;

    @include respondMf(xlg) {
      margin-bottom: 1rem;
    }
  }

  .content p {
    color: $color-primary-light;
  }

  .p--xlg p {
    font-size: 2rem;
    
    @include respondMf(xlg) {
      font-size: 2.4rem;
      line-height: 1.5;
    }
  }
}

.footer-label__link {
  align-items: center;
  color: #fff;
  display: inline-flex;
  font-size: 2.4rem;
  text-decoration: none;
  transition: opacity 0.25s;

  @include respondMf(xlg) {
    font-size: 3rem;
  }

  &:hover {
    opacity: 0.7;
  }
}

.footer-label__link-img {
  height: 3rem;
  margin-right: 2.5rem;

  @include respondMf(xlg) {
    height: 4rem;
  }
}
