
.u-ta-c {
  text-align: center;
}

.u-ta-r {
  text-align: right;
}

.u-ta-l {
  text-align: left;
}

.u-t-rotate-180 {
  transform: rotate(180deg);
}

.u-mrg-horizontal-auto {
  margin-left: auto;
  margin-right: auto;
}

.u-va-m {
  vertical-align: middle;
}

@include respondMf(sm) {

  .u-sm-ta-l {
    text-align: left;
  }

  .u-sm-ta-r {
    text-align: right;
  }
}

@include respondMf(md) {

  .u-md-ta-l {
    text-align: left;
  }
}

@include respondMf(lg) {

  .u-lg-ta-l {
    text-align: left;
  }
}
