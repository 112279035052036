
// desktop headline sizes
$headline-sizes: (
        h1: 8rem,
        h2: 4.8rem,
        h3: 4.8rem,
        h4: 3rem,
        h5: 2.4rem,
        h6: 1.8rem
);

// headline colors
$headline-colors: (
        h1: $color-primary,
        h2: $color-primary,
        h3: $color-text,
        h4: $color-text,
        h5: $color-text,
        h6: $color-text
);

$h1-size: map-get($headline-sizes, 'h1');
$h2-size: map-get($headline-sizes, 'h2');
$h3-size: map-get($headline-sizes, 'h3');
$h4-size: map-get($headline-sizes, 'h4');
$h5-size: map-get($headline-sizes, 'h5');
$h6-size: map-get($headline-sizes, 'h6');

.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.content h1 {
  margin: 0 0 3rem;
  font-family: $font-family-2;
  font-size: calc(#{$h1-size} - #{$h1-size}*.333);
  line-height: 1;
  color: map-get($headline-colors, 'h1');

  font-weight: 700;

  @include respondMf(md) {
    font-size: $h1-size;
  }

  @include respondMf(xlg) {
    line-height: 1.2;
  }
}

.content h2 {
  margin: calc(#{$h1-size} - #{$h2-size}) 0 3rem;
  font-family: $font-family-2;
  font-size: calc(#{$h2-size} - #{$h2-size}*.333);
  color: map-get($headline-colors, 'h2');

  @include respondMf(md) {
    font-size: $h2-size;
  }

  font-weight: 700;
}

.content h3 {
  margin: calc(#{$h1-size} - #{$h3-size}) 0 3rem;
  font-family: $font-family-2;
  font-size: calc(#{$h3-size} - #{$h3-size}*.333);
  color: map-get($headline-colors, 'h3');

  @include respondMf(md) {
    font-size: $h3-size;
  }

  font-weight: 700;
}

.content h4 {
  margin: calc(#{$h1-size} - #{$h4-size}) 0 3rem;
  font-size: calc(#{$h4-size} - #{$h4-size}*.333);
  color: map-get($headline-colors, 'h4');

  @include respondMf(md) {
    font-size: $h4-size;
  }

  font-weight: 700;
}

.content h5 {
  margin: calc(#{$h1-size} - #{$h5-size}) 0 3rem;
  font-size: calc(#{$h5-size} - #{$h5-size}*.333);
  color: map-get($headline-colors, 'h5');

  @include respondMf(md) {
    font-size: $h5-size;
  }

  font-weight: 700;
}

.content h6 {
  margin: calc(#{$h1-size} - #{$h6-size}) 0 3rem;
  font-size: calc(#{$h6-size} - #{$h6-size}*.333);
  letter-spacing: .05em;
  color: map-get($headline-colors, 'h6');

  @include respondMf(md) {
    font-size: $h6-size;
  }

  text-transform: uppercase;
  font-weight: 700;
}

.title-group {
  small {
    color: $color-primary;
    font-size: 1.8rem;
    font-weight: bold;
    font-family: $font-family-2;
  }

  h2 {
    margin-top: 1rem;
  }
}

.content p {
  margin: 0 0 3rem;
  font-size: 15px;
  line-height: 1.875;
  color: $color-text;

  &:last-child {
    margin-bottom: 0;
  }
}

.content a {
  text-decoration: underline;
  font-size: inherit;
  letter-spacing: .05em;
  transition: .25s color;
  will-change: color;

  &:link,
  &:visited {
    color: $color-text;
  }

  &:hover,
  &:active {
    color: $color-primary;
  }
}

.content ul {
  margin: 0 0 3rem;
  padding: 0;
  list-style: none;

  li {
    position: relative;
    margin: 0 0 1.5rem;
    padding: 0 0 0 2rem;
    font-size: 1.6rem;
    line-height: 1.286;
    color: $color-text;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  li::before {
    content: '';
    position: absolute;
    top: .5rem;
    left: 0;
    width: .7rem;
    height: .7rem;
    border-radius: 50%;
    background-color: $color-text;
  }

  a:link,
  a:visited {
    color: $color-primary;
    text-decoration: none;
    transition: opacity 0.25s;
  }

  a:hover,
  a:active {
    opacity: 0.6;
  }
}

.arrow-link {
  color: $color-primary;
  font-family: $font-family-2;
  font-size: 1.6rem;
  padding-right: 2.4rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: opacity 0.25s;

  &::after {
    background-image: url('#{$path-image}/icons/chevron-down.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    content: '';
    height: 100%;
    margin-left: 1rem;
    position: absolute;
    top: 0;
    transition: top 0.25s;
    width: 1.4rem;
  }

  &:hover {
    opacity: 0.7;

    &::after {
      top: 0.5rem;
    }
  }
}

.arrow-link--white {
  color: #fff;

  &::after {
    background-image: url('#{$path-image}/icons/chevron-down-white.svg');
  }
}

.content.ul-symbol ul {
  li {
    padding-left: 3rem;

    &:hover::before {
      transform: scale(1.2);
    }
  }

  li::before {
    background: url('#{$path-image}/icons/i-list-item.svg');
    background-repeat: no-repeat;
    border-radius: 0;
    height: 1rem;
    transition: transform 0.25s;
    width: 1rem;
  }
}
