
/*
** One file to rule them all,
** One file to find Them,
** One file to bring them all,
** And in the Sass way merge them.
**
 */

// npm
@import 'tiny-slider/src/tiny-slider';
@import 'photoswipe/src/css/main';
@import 'photoswipe/src/css/default-skin/default-skin';
//@import 'pikaday/scss/pikaday';

// abstract
@import 'abstract/vars';
@import 'abstract/mixins';
@import 'abstract/animations';

// vendors
@import 'vendors/normalize';
@import 'vendors/reboot';
@import 'vendors/flatpickr';
@import 'vendors/micromodal';

// base
@import 'base/init';
@import 'base/typo';
@import 'base/a11y';
@import 'base/grid';

// layout
@import 'layout/footer';
@import 'layout/header';
@import 'layout/menu';
@import 'layout/nav';
@import 'layout/article';
@import 'layout/table';
@import 'layout/sub-header';
@import 'layout/we-have-cookies';

// sections
@import 'sections/section';

// components
@import 'components/icon-horizontal';
@import 'components/gallery';
@import 'components/gallery-grid';
@import 'components/footer-label';
@import 'components/btn';
@import 'components/article-list';
@import 'components/food-menu';
@import 'components/accordion';
@import 'components/breadcrumb';
@import 'components/carousel-navigation';
@import 'components/header-carousel';
@import 'components/box';
@import 'components/benefit';
@import 'components/google-map';
@import 'components/quot';
@import 'components/photo-swipe';

// form
@import 'form/form-vars';
@import 'form/form-container';
@import 'form/form-input';
@import 'form/form-label';
@import 'form/form-error';
@import 'form/form-box';
@import 'form/custom-checkbox';

// utilities
@import 'utilities/display';
@import 'utilities/margins-paddings';
@import 'utilities/alignment';
@import 'utilities/font';
@import 'utilities/sizes';

