.carousel-navigation {
  display: flex;
}

.carousel-navigation--bg-1 {
  .carousel-control {
    background-color: $color-background;
  }
}

.carousel-control {
  background-color: #fff;
  cursor: pointer;
  height: 4rem;
  padding: 1.3rem 1.5rem;
  transition: background-color 0.25s;

  &:hover,
  &:focus  {
    background-color: $color-primary;
    outline: none;

    .carousel-control__icon {
      fill: #fff;
    }
  }
}

.carousel-control__prev {
  border-bottom-left-radius: 3rem;
  border-top-left-radius: 3rem;
  border-right: 1px solid $color-border;
  padding-right: 3rem;
}

.carousel-control__next {
  border-bottom-right-radius: 3rem;
  border-top-right-radius: 3rem;
  padding-left: 3rem;
}

.carousel-control__icon {
  fill: $color-primary;
  height: 1.4rem;
  transition: fill 0.25s;
}