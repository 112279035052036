
.custom-checkbox {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: left;

  .vivalid-error {
    position: absolute;
    top: 100%;
    left: 0;
  }
}

/* Styles for hiding the native checkbox */
.custom-checkbox__check {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

/* Styles for the basic appearance of the custom checkbox */
.custom-checkbox__toggle {
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  flex: 0 0 2rem;
  vertical-align: middle;
  cursor: pointer;

  .custom-checkbox--multiline & {
    top: .2rem;
  }
}

.custom-checkbox__toggle::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: .2rem solid #fff;
  background-color: #fff;
  transition: .25s all;

  .custom-checkbox__check:hover ~ & {
    box-shadow: 0 0 0 .2rem rgba(#fff, 0.25);
  }
  .custom-checkbox__check:checked ~ & {
    background-color: #fff;
    border-color: #fff;
  }

  .form-container--has-error & {
    border-color: $color-error;
  }
}

.custom-checkbox__check:checked~.custom-checkbox__toggle::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  $check-color: str-replace('#{$color-primary}', '#', '%23');
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='#{$check-color}' stroke-width='6' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'/%3E%3C/svg%3E") center 60% no-repeat;
  background-size: 80%;
  z-index: 5;
}

.custom-checkbox__label {
  display: inline-block;
  vertical-align: middle;
  flex: 0 0 calc(100% - 1.5rem);
  width: calc(100% - 1.5rem);
  max-width: calc(100% - 1.5rem);
  padding: 0 0 0 1rem;
  font-size: 1.5rem;
  line-height: 1.4;
  color: #fff;
  user-select: none;
}
