
.container {
  width: 100%;
  max-width: 128rem;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.container-full {
  max-width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.5rem;
}

.row--no-gutter {
  .col:first-of-type {
    padding-left: 0;
  }

  .col:last-of-type {
    padding-right: 0;
  }
}

.row--50-50 {

  .col--6:nth-of-type(odd) {
    padding-right: .75rem;

    @include respondMf(xlg) {
      padding-right: 2rem;
    }
  }
  .col--6:nth-of-type(even) {
    padding-left: .75rem;

    @include respondMf(xlg) {
      padding-left: 2rem;
    }
  }
}

.row--lg-33 {

  .col--6:nth-of-type(odd) {

    @include respondMf(lg) {
      padding-right: 2rem;
    }
  }

  .col--6 {

    @include respondMf(lg) {
      padding-left: 2rem !important;
      padding-right: 2rem !important;
    }
  }

  .tile {
    height: 100%;
  }
}

@include respondMf(md) {
  .row--md-light .col {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.row--sm-gutter {
  margin: 0 -0.9rem;

  .col {
    padding-left: 0.9rem;
    padding-right: 0.9rem;
  }
}

.col {
  flex: 0 0 100%;
  position: relative;
  width: 100%;
  max-width: 100%;
  min-height: 1px;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.col--auto-width {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col--1 {
  flex: 0 0 8.3333%;
  width: 8.3333%;
  max-width: 8.3333%;
}

.col--2 {
  flex: 0 0 16.6667%;
  width: 16.6667%;
  max-width: 16.6667%;
}

.col--2-5 {
  flex: 0 0 20%;
  width: 20%;
  max-width: 20%;
}

.col--3 {
  flex: 0 0 25%;
  width: 25%;
  max-width: 25%;
}

.col--3-5 {
  flex: 0 0 30%;
  width: 30%;
  max-width: 30%;
}

.col--4 {
  flex: 0 0 33.3333%;
  width: 33.3333%;
  max-width: 33.3333%;
}

.col--5 {
  flex: 0 0 41.6667%;
  width: 41.6667%;
  max-width: 41.6667%;
}

.col--6 {
  flex: 0 0 50%;
  width: 50%;
  max-width: 50%;
}

.col--7 {
  flex: 0 0 58.3333%;
  width: 58.3333%;
  max-width: 58.3333%;
}

.col--7-2-5 {
  flex: 0 0 60%;
  width: 60%;
  max-width: 60%;
}

.col--8 {
  flex: 0 0 66.6667%;
  width: 66.6667%;
  max-width: 66.6667%;
}

.col--8-5 {
  flex: 0 0 70%;
  width: 70%;
  max-width: 70%;
}

.col--9 {
  flex: 0 0 75%;
  width: 75%;
  max-width: 75%;
}

.col--10 {
  flex: 0 0 83.3333%;
  width: 83.3333%;
  max-width: 83.3333%;
}

.col--11 {
  flex: 0 0 91.6667%;
  width: 91.6667%;
  max-width: 91.6667%;
}

.col--right-1 {
  margin-right: 8.3333%;
}

.col--right-2 {
  margin-right: 16.6667%;
}

.col--right-3 {
  margin-right: 25%;
}

.col--right-4 {
  margin-right: 33.3333%;
}

.col--right-5 {
  margin-right: 41.6667%;
}

.col--right-6 {
  margin-right: 50%;
}

.col--right-7 {
  margin-right: 58.3333%;
}

.col--right-8 {
  margin-right: 66.6667%;
}

.col--right-9 {
  margin-right: 75%;
}

.col--right-10 {
  margin-right: 83.3333%;
}

.col--right-11 {
  margin-right: 91.6667%;
}

.col--left-1 {
  margin-left: 8.3333%;
}

.col--left-2 {
  margin-left: 16.6667%;
}

.col--left-3 {
  margin-left: 25%;
}

.col--left-4 {
  margin-left: 33.3333%;
}

.col--left-5 {
  margin-left: 41.6667%;
}

.col--left-6 {
  margin-left: 50%;
}

.col--left-7 {
  margin-left: 58.3333%;
}

.col--left-8 {
  margin-left: 66.6667%;
}

.col--left-9 {
  margin-left: 75%;
}

.col--left-10 {
  margin-left: 83.3333%;
}

.col--left-11 {
  margin-left: 91.6667%;
}

.col--auto {
  margin-left: auto;
  margin-right: auto;
}

@each $break, $break-value in $grid-breakpoints-mf {

  @include respondMf($break) {
    .col--#{$break}-auto-width {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
  
    .col--#{$break}-1 {
      flex: 0 0 8.3333%;
      width: 8.3333%;
      max-width: 8.3333%;
    }
    .col--#{$break}-2 {
      flex: 0 0 16.6667%;
      width: 16.6667%;
      max-width: 16.6667%;
    }
    .col--#{$break}-2-5 {
      flex: 0 0 20%;
      width: 20%;
      max-width: 20%;
    }
    .col--#{$break}-3 {
      flex: 0 0 25%;
      width: 25%;
      max-width: 25%;
    }
    .col--#{$break}-3-5 {
      flex: 0 0 30%;
      width: 30%;
      max-width: 30%;
    }
    .col--#{$break}-4 {
      flex: 0 0 33.3333%;
      width: 33.3333%;
      max-width: 33.3333%;
    }
    .col--#{$break}-5 {
      flex: 0 0 41.6667%;
      width: 41.6667%;
      max-width: 41.6667%;
    }
    .col--#{$break}-6 {
      flex: 0 0 50%;
      width: 50%;
      max-width: 50%;
    }
    .col--#{$break}-7 {
      flex: 0 0 58.3333%;
      width: 58.3333%;
      max-width: 58.3333%;
    }
    .col--#{$break}-7-2-5 {
      flex: 0 0 60%;
      width: 60%;
      max-width: 60%;
    }
    .col--#{$break}-8 {
      flex: 0 0 66.6667%;
      width: 66.6667%;
      max-width: 66.6667%;
    }
    .col--#{$break}-8-5 {
      flex: 0 0 70%;
      width: 70%;
      max-width: 70%;
    }
    .col--#{$break}-9 {
      flex: 0 0 75%;
      width: 75%;
      max-width: 75%;
    }
    .col--#{$break}-10 {
      flex: 0 0 83.3333%;
      width: 83.3333%;
      max-width: 83.3333%;
    }
    .col--#{$break}-11 {
      flex: 0 0 91.6667%;
      width: 91.6667%;
      max-width: 91.6667%;
    }
    .col--#{$break}-12 {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }
    .col--#{$break}-right-0 {
      margin-right: 0;
    }
    .col--#{$break}-right-1 {
      margin-right: 8.3333%;
    }
    .col--#{$break}-right-2 {
      margin-right: 16.6667%;
    }
    .col--#{$break}-right-3 {
      margin-right: 25%;
    }
    .col--#{$break}-right-4 {
      margin-right: 33.3333%;
    }
    .col--#{$break}-right-4-5 {
      margin-right: 37.5%;
    }
    .col--#{$break}-right-5 {
      margin-right: 41.6667%;
    }
    .col--#{$break}-right-6 {
      margin-right: 50%;
    }
    .col--#{$break}-right-7 {
      margin-right: 58.3333%;
    }
    .col--#{$break}-right-8 {
      margin-right: 66.6667%;
    }
    .col--#{$break}-right-9 {
      margin-right: 75%;
    }
    .col--#{$break}-right-10 {
      margin-right: 83.3333%;
    }
    .col--#{$break}-right-11 {
      margin-right: 91.6667%;
    }
    .col--#{$break}-left-0 {
      margin-left: 0;
    }
    .col--#{$break}-left-0-5 {
      margin-left: 4.5%;
    }
    .col--#{$break}-left-1 {
      margin-left: 8.3333%;
    }
    .col--#{$break}-left-1-5 {
      margin-left: 12.5%;
    }
    .col--#{$break}-left-2 {
      margin-left: 16.6667%;
    }
    .col--#{$break}-left-3 {
      margin-left: 25%;
    }
    .col--#{$break}-left-4 {
      margin-left: 33.3333%;
    }
    .col--#{$break}-left-4-5 {
      margin-left: 37.5%;
    }
    .col--#{$break}-left-5 {
      margin-left: 41.6667%;
    }
    .col--#{$break}-left-6 {
      margin-left: 50%;
    }
    .col--#{$break}-left-7 {
      margin-left: 58.3333%;
    }
    .col--#{$break}-left-8 {
      margin-left: 66.6667%;
    }
    .col--#{$break}-left-9 {
      margin-left: 75%;
    }
    .col--#{$break}-left-10 {
      margin-left: 83.3333%;
    }
    .col--#{$break}-left-11 {
      margin-left: 91.6667%;
    }

    .col--#{$break}-auto {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@include respondMf(md) {

  .col--md-sm-pdg-r {
    padding-right: 1rem;
  }
  .col--md-sm-pdg-l {
    padding-left: 1rem;
  }
}

@include respondMf(xlg) {

  .col--xlg-4-5 {
    flex: 0 0 37.5%;
    width: 37.5%;
    max-width: 37.5%;
  }

  .col--xlg-sm-pdg-r {
    padding-right: 1rem;
  }

  .col--xlg-sm-pdg-l {
    padding-left: 1rem;
  }

  .col--xlg-md-pdg-r {
    padding-right: 1.5rem;
  }

  .col--xlg-no-gutter {

    .row {
      margin: 0 -3rem;
    }

    .col:first-of-type {
      padding-left: 0;
    }
    .col:last-of-type {
      padding-right: 0;
    }
  }

  .col--xlg-right-0-5 {
    margin-right: 4%;
  }

  .col--xlg-7-5 {
    flex: 0 0 62.6667%;
    width: 62.6667%;
    max-width: 62.6667%;
  }
}
