
.form-error {
  display: block;
  overflow: hidden;
  max-height: 0;
  font-size: 1.4rem;
  line-height: 1.286;
  letter-spacing: .05em;
  color: $form-error-color;
  transition: .25s max-height;
  will-change: max-height;

  .red-var & {
    overflow: visible;
    color: red;
  }

  &--nette {
    overflow: visible;
    list-style: none;
    margin: 0 0 1rem 0;
    text-align: center;
    max-height: initial;

    > li {
      margin-bottom: 0.5rem;
      font-size: 2rem;
    }
  }
}


.vivalid-error {
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
  order: 3;
  display: block;
  margin: .5rem 0 0 2rem;
  font-family: $font-family-2;
  font-size: 1.2rem;
  line-height: 1.286;
  letter-spacing: .05em;
  color: $form-error-color !important;
}

.custom-checkbox .vivalid-error {
  margin: .5rem 0 0 2.5rem;

  @include respondMf(md) {
    margin: .5rem 0 0 3rem;
  }
}

.form-error--is-active {
  margin: .5rem 0 0;
  max-height: 5rem;
}

// vivalid error & choices.js
.js-single-select-helper.vivalid-error-input + .vivalid-error {
  position: absolute;
  top: 100%;
}
.js-single-select-helper.vivalid-error-input + .vivalid-error + .choices {
  margin-bottom: 3rem;
}
.js-single-select-helper.vivalid-error-input + .vivalid-error + .choices .choices__inner {
  border-color: $form-error-color;

  .red-var & {
    border-color: red;
  }
}
