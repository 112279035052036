.pswp {

  &__button--arrow--left,
  &__button--arrow--right {

    &:before {
      background-color: $color-primary !important;
    }
  }

}