.accordion {
  position: relative;
  z-index: 10;
  max-width: 32rem;
  background-color: #fff;
  box-shadow: 0px 5px 30px 0px rgba(#000, 0.1);

  @include respondMf(xlg) {
    width: 32rem;
  }
}

.accordion__list {
  list-style: none;
  margin: 0;
  padding: 0;

  li:last-of-type {
    .accordion__item {
      border-bottom-color: transparent;

      &:hover {
        border-bottom-color: $color-primary;
      }
    }
  }
}

.accordion__input {
  display: none;


  &:checked + .accordion__item::before {
    transform: rotate(-90deg);
    height: 1.1rem;
    width: 1rem;
    top: 2.4rem;
  }

  &:checked + .accordion__item:hover::before {
    transform: rotate(-90deg) scale(1.2);
  }

  &:checked ~ .accordion__list {
    border-bottom: 1px solid $color-border;
    padding: 0.5rem 2rem 0.5rem 3rem;
  }

  &:checked ~ .accordion__list .accordion__item {
    max-height: 7rem;
    opacity: 1;
    padding: 1.5rem 2rem 1.5rem 4.5rem;

    &::before {
      top: 2rem;
    }
  }
}

.accordion__item {
  border-bottom: 1px solid $color-border;
  color: $color-text;
  cursor: pointer;
  display: block;
  font-family: $font-family-2;
  font-size: 1.8rem;
  font-weight: bolder;
  padding: 2rem 2rem 2.1rem 5rem;
  position: relative;
  text-decoration: none;
  transition: all 0.25s;

  &:hover {
    border-bottom: 2px solid $color-primary;
    color: $color-primary;
    padding-bottom: 2rem;

    &::before {
      transform: scale(1.2);
    }
  }

  &::before {
    background: url('#{$path-image}/icons/i-list-item.svg');
    background-repeat: no-repeat;
    content: '';
    height: 1rem;
    left: 2rem;
    position: absolute;
    top: 2.6rem;
    transition: transform 0.25s;
    width: 1.1rem;
  }
}

.accordion__item + .accordion__list {
  background: $color-background;
  overflow: hidden;
  padding-left: 3rem;
  transition: padding 0.25s;

  .accordion__item {
    border-bottom: 0;
    font-size: 1.6rem;
    max-height: 0;
    opacity: 0;
    padding: 0 2rem 0 4.5rem;
    transition: all 0.25s;

    &::before {
      background: url('#{$path-image}/icons/i-list-item2.svg');
      height: 0.8rem;
      top: 0;
      transition: transform 0.25s, top 0.25s;
      width: 0.8rem;
    }
  }
}
