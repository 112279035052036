.gallery-container {
  @media only screen and (min-width: 1280px) {
    margin-left: calc((100% - 128rem) / 2);
    margin-right: -1.5rem; // because of .gallery__item padding
  }
}

.gallery-navigation {
  margin-top: -2rem;
  position: absolute;
  right: 3rem;
  z-index: 5;

  @media only screen and (min-width: 1280px) {
    right: calc((100% - 128rem) / 2 + 6rem);
  }
}

.gallery__item {
  padding: 0 1.5rem;
}

.gallery__image {
  border: 1.5rem solid rgba(#000, 0.3);
  margin: 0;
  overflow: hidden;

  &:hover {
    img {
      transform: scale(1.1)
    }
  }

  img {
    transition: transform 0.4s;
  }
}

.gallery__link {
  display: block;
  width: 100%;
  //width: 27.5rem;
  height: 21.4rem;

  @include respondMf(md) {
    height: 16rem;
  }

  @include respondMf(lg) {
    height: 12rem;
  }

  @media only screen and (min-width: 1366px) {
    height: 21.4rem;
  }
}

.gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  font-family: 'object-fit:cover;object-position: center center;';
}