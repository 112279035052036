.breadcrumb {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 3.6rem 0 0;
  padding: 0;
}

.breadcrumb__item {
  color: $color-light-text;
  font-size: 1.6rem;
  padding-right: 5.7rem;
  position: relative;

  &::after {
    background-image: url('#{$path-image}/icons/i-breadcrumb-chevron.svg');
    content: '';
    height: 1rem;
    position: absolute;
    right: 2.5rem;
    top: 0.5rem;
    width: 0.7rem;
  }

  &:last-of-type {
    padding-right: 0;

    &::after {
      display: none;
    }
  }

  a {
    border-bottom: 1px solid $color-text;
    color: inherit;
    display: inline-block;
    font-weight: bolder;
    text-decoration: none;
    transition: color 0.25s, border 0.25s;
  }

  a:hover {
    color: $color-primary;
    border-bottom-color: $color-primary;
  }
}