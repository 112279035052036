
.menu-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 2rem 0;
  background-color: transparent;
  transition: .25s background-color, .25s padding;
  will-change: background-color, padding;

  @include respondMf(lg) {
    padding: 3rem 0;
  }
}

.menu-container--is-scrolled,
.menu-container--submenu {
  box-shadow: 0 4rem 3rem rgba(0, 0, 0, 0.05);
  background-color: #fff;

  @include respondMf(lg) {
    .menu a {
      &:link,
      &:visited {
        color: $color-primary;
      }

      &.btn {
        border-color: $color-primary;
      }

      &.btn:active,
      &.btn:hover {
        background-color: $color-primary;
        color: #fff;
      }
    }
  }
}

.menu-container--is-scrolled {
  padding: 1rem 0;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu__logo {
  height: 3.4rem;
  z-index: 1010;

  @include IESelector() {
    width: 14rem;
  }

  .menu-container--is-scrolled & {
    height: 2.4rem;

    @include respondMf(lg) {
      height: 3.4rem;
    }
  }
}

.menu-links {
  display: flex;

  @include respondMf(lg) {
    align-items: center;
    justify-content: flex-end;
    flex: 1;
  }
}

.menu a {
  &:link,
  &:visited {
    color: #fff;
  }

  &.btn:active,
  &.btn:hover {
    color: $color-primary;
  }

  &.btn {
    @include respondMf(xlg) {
      margin-right: 0;
    }
  }
}

.menu a.nav-fb,
.menu a.nav-ig {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  margin: 2rem 0 0 4rem;
  border: .1rem solid #fff;
  border-radius: 50%;
  transition: .25s background-color;
  will-change: background-color;

  @include respondMf(lg) {
    margin: 0 0 0 3rem;
  }

  .menu-container--is-scrolled &,
  .menu-container--submenu & {
    @include respondMf(lg) {
      border-color: $color-primary;
    }
  }

  svg {
    width: 1.15rem;
    height: 2.05rem;
  }

  .st0 {
    fill: #fff;
    transition: .25s fill;
    will-change: fill;

    .menu-container--is-scrolled &,
    .menu-container--submenu & {
      @include respondMf(lg) {
        fill: $color-primary;
      }
    }
  }

  &:hover {
    background-color: #fff;

    .st0 {
      fill: $color-primary;
    }
  }

  .menu-container--is-scrolled &:hover,
  .menu-container--submenu &:hover {
    @include respondMf(lg) {
      background-color: $color-primary;

      .st0 {
        fill: #fff;
      }
    }
  }
}

.menu a.nav-ig {

  svg {
    width: 2.7rem;
    height: 2.7rem;
  }
}
.nav__item--social {
  display: flex;
}