
.form-container {
  position: relative;
  margin: 0 0 1rem;

  &__contact-type-wrapper {
    display: flex;

    > * {
      margin-right: 1rem;
    }
  }

  a {
    color: #FFFFFF !important;

    &:hover,
    &:focus,
    &:visited {
      color: $color-border !important;
    }
  }
}

.form-container--footer {
  margin: 3.5rem 0 0;

  .content p {
    font-size: 1.4rem;
    font-weight: 300;
  }
}


