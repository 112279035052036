.sub-header {
  margin-top: 7.6rem;
  padding: 7.6rem 0;
  position: relative;

  @include respondMf(lg) {
    margin-top: 11.8rem;
    padding: 14rem 0;
  }
}

.sub-header__bg {
  font-family: 'object-fit:cover;object-position: center center;';
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}