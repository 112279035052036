
.form-input {
  width: 100%;
  padding: 0 2rem;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: .05em;
  line-height: 1;
  height: 5rem;
  border: none;
  color: $form-text-color;
  transition: box-shadow 0.25s;
}

.form-input:not(.form-input--error):focus {
  box-shadow: 0 0 0 0.3rem rgba($border-focus-color, 0.3);
}

.form-input--error {
  box-shadow: 0 0 0 0.3rem rgba($form-error-color, 0.6);
}

.form-input--textarea {
  height: 16.5rem;
  padding: 2rem;
  line-height: 1.286;
  resize: none;
}

textarea.form-input {
  padding: 2rem;
  height: 20rem;
}