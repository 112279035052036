.article-list__item {
  border-bottom: 1px solid $color-border;
  padding: 3.6rem 0;

  &:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

.article-list__title {
  border-bottom: 1px solid $color-primary;
  color: $color-primary;
  display: inline-block;
  font-family: $font-family-2;
  font-size: 2.4rem; 
  margin: 0 0 1rem;
  transition: color 0.25s, border-color 0.25s;

  a {
    color: inherit;
    text-decoration: none;
  }

  &:hover {
    border-color: $color-primary-dark;
    color: $color-primary-dark;
  }
}

.article-list__perex {
  color: $color-light-text;
  font-size: 1.5rem;
  line-height: 1.6;
  margin: 0 0 2rem;
}

.article-list__link {
  align-items: center;
  display: inline-flex;
  text-decoration: none;

  &:hover {
    .article-list__link-text {
      color: $color-primary-dark;
    }

    .article-list__link-icon {
      fill: $color-primary-dark;
    }
  }
}

.article-list__link-text {
  border-bottom: 1px solid $color-primary;
  color: $color-primary;
  font-family: $font-family-2;
  font-size: 1.5rem;
  font-weight: bolder;
  transition: color 0.25s;
}

.article-list__link-container {
  height: 1.5rem;
  margin-left: 0.7rem;
}

.article-list__link-icon {
  fill: $color-primary;
  height: 1.5rem;
  transition: fill 0.25s;
  width: 1.5rem;
}

