.quot {
  text-align: center;
}

.quot__head {
  align-items: center;
  border: 1px solid $color-primary;
  border-radius: 50%;
  display: flex;
  height: 4.8rem;
  margin: 0 auto 4rem auto;
  padding: 0.8rem;
  width: 4.8rem;
}

.quot__logo {
  font-family: 'object-fit:cover;object-position: center center;';
  margin-left: 0.2rem;
  object-fit: cover;
  object-position: center center;
}

.quot__footer {
  margin-top: 3rem;
  padding-top: 3rem;
  position: relative;

  &::before {
    background-color: $color-border;
    content: '';
    height: 1px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 6.5rem;
  }

  .content p {
    color: $color-primary;
    text-transform: uppercase;
  }

  .content strong {
    font-size: 1.8rem;
    text-transform: none;
  }
}