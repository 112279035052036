html {
  font-size: 62.5%; // 1rem = 10px
}

body {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  overflow-x: hidden;
  max-width: 100vw;
  font-family: $font-family-1;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

input:focus,
button:focus,
textarea:focus {
  outline: none;
}

textarea,
input,
input[type="text"],
input[type="password"],
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
}

input[type="submit"] {
  cursor: pointer;
}

a {
  transition: .25s color;
  will-change: color;

  &:visited,
  &:link {

  }

  &:hover,
  &:active {

  }
}

img {
  max-width: 100%;
}

.pswp__img {
  object-fit: cover;
  object-position: center center;
  font-family: 'object-fit:cover;object-position: center center;';
}
