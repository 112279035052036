
.box-wrapper {
  -webkit-transform:translate3d(0,0,0);
}

.box {
  box-shadow: 0.4rem 0.4rem 3rem rgba(#000, 0.05);
  position: relative;
  height: 39.5rem;
  transition-duration: 0.6s;
  transition-timing-function: ease-in-out;
  transform-style: preserve-3d;
  -webkit-transform: rotateY(0deg) translate3d(0,0,0);
  transform: rotateY(0deg) translate3d(0,0,0);

  .box-wrapper:hover & {
    transform: rotateY(-180deg) translate3d(0,0,0);
  }

  @include respondMf(xlg) {
    height: 53.5rem;
  }
}

.box__inner {
  position: relative;
  z-index: 2;
  padding: 3.4rem;
  overflow: auto;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.box__inner .content h3 {
  font-size: 2.4rem;
  font-weight: 400;
  text-transform: uppercase;
}

.box__inner .content p {
  font-size: 1.6rem;
  line-height: 1.4;
  color: #fff;
}

.box__front,
.box__back {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
}

.box__front {
  z-index: 2;
  -webkit-transform: rotateY(0) translate3d(0,0,0);
  transform: rotateY(0) translate3d(0,0,0);
}

.box__front .content h3 {
  color: $color-primary;
}

.box__back {
  background-color: $color-primary;
  -webkit-transform: rotateY(-180deg) translate3d(0,0,0);
  transform: rotateY(-180deg) translate3d(0,0,0);
}

.box__back .content h3 {
  color: #fff;
}

.box__front::before {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.box__img {
  font-family: 'object-fit:cover;object-position: center center;';
  height: 20rem;
  margin: -3.4rem -3.4rem 3.2rem;
  max-width: none;
  object-fit: cover;
  object-position: center center;
  width: calc(100% + 6.8rem);

  @include respondMf(xlg) {
    height: 34rem;
  }
}