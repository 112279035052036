
// mobile first
@mixin respondMf($breakpoint) {
  @each $breakpointName, $breakpointPx in $grid-breakpoints-mf {
    @if $breakpoint == $breakpointName {
      @media only screen and (min-width:$breakpointPx) {
        @content;
      }
    }
  }
}

// IE 11 selector
@mixin IESelector() {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

// Edge selector
@mixin EdgeSelector() {
  @supports (-ms-ime-align:auto) {
    @content;
  }
}

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}
