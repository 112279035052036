.form-box {
  position: relative;
  z-index: 1;
  background-color: $color-primary;
  box-shadow: 0.4rem 0.4rem 3rem rgba(#000, 0.3);
  display: none;
  padding: 2.5rem;

  @include respondMf(lg) {
    display: block;
    padding: 5rem 3.8rem;
  }

  .content p {
    color: #fff;
  }

  .content h2 {
    color: #fff;
    font-size: 3.6rem;
    font-weight: 300;
    width: calc(100% - 3.5rem);
  }
}

.form-box-open {
  display: inline-block;
  margin: 0 0 14.5rem;

  @include respondMf(lg) {
    display: none;
  }
}

.form-box-close {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg id='Outlined' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle/%3E%3Cg id='Fill'%3E%3Cpolygon fill='%23fff' points='28.71 4.71 27.29 3.29 16 14.59 4.71 3.29 3.29 4.71 14.59 16 3.29 27.29 4.71 28.71 16 17.41 27.29 28.71 28.71 27.29 17.41 16 28.71 4.71'/%3E%3C/g%3E%3C/svg%3E");
  cursor: pointer;
  color: transparent;
  position: absolute;
  right: 0;
  top: 0.8rem;
  z-index: 5;

  @include respondMf(lg) {
    display: none;
  }
}